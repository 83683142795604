import { useDispatch, useSelector } from 'react-redux';
import DetailCard from '../../components/detailCard/DetailCard';
import Loader from '../../components/loader/Loader';
import CustomTable from '../../components/customtable/CustomTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getSentenceCase } from '../../utils/commonUtils';
import {
  fetchProfilesForReviewRequest,
  fetchProfilesForEditFormRequest,
  updateProfileReviewStatusRequest,
  mergeProfileRequest
} from '../../store/duplicateProfiles/actions';
import { useParams, useHistory } from 'react-router-dom';
import ERRORS from '../../constants/error';
import toastCenter from '../../utils/toastCenter';
import APPCONSTANTS from '../../constants/appConstants';
import {
  loadingSelector,
  profileReviewDataSelector,
  profileEditFormDataSelector
} from '../../store/duplicateProfiles/selectors';
import Modal from '../../components/modal/ModalForm';
import PatientDetailsEditForm from './PatientDetailsEditForm';
import styles from './ReviewDuplicates.module.scss';
import SuccessIcon from '../../assets/images/success-blue.svg';
import ErrorIcon from '../../assets/images/Failed.svg';
import { PROTECTED_ROUTES } from '../../constants/route';
import sessionStorageServices from '../../global/sessionStorageServices';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';

interface IMatchParams {
  groupId: string;
  regionId: string;
  regionName: string;
  siteId: string;
  siteName: string;
}

interface IFormField {
  name: string;
  label: string;
  isEdit: boolean;
  hasId?: boolean;
  id?: string;
  other?: string;
}

const ReviewDuplicates = (): React.ReactElement => {
  const loading = useSelector(loadingSelector);
  const profileReviewData = useSelector(profileReviewDataSelector);
  const profileEditFormData = useSelector(profileEditFormDataSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const { groupId, regionId, regionName, siteId, siteName } = useParams<IMatchParams>();
  const isUnsure = sessionStorageServices.getItem(APPCONSTANTS.IS_UNSURE_RECORD) || APPCONSTANTS.FALSE;
  const [totalCount, setTotalCount] = useState(sessionStorageServices.getItem(APPCONSTANTS.TOTAL_COUNT));
  const [profileComparisionData, setProfileComparisionData] = useState({});
  const [reviewData, setReviewData] = useState<any>({ patient: {} });
  const [patienEditForm, setPatienEditForm] = useState({ show: false });
  const [finalReviewModal, setFinalReviewModal] = useState({ show: false });
  const [alertModal, setAlertModal] = useState({
    show: false,
    status: '',
    message: ''
  });
  const [skipProfiles, setSkipProfiles] = useState(0);
  const [finalAlert, setFinalAlert] = useState(false);
  const [lastMarkedUnsure, setLastMarkedUnsure] = useState(false);
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';

  const reqPayload = useCallback((groupId: string, isUnsure: boolean, skipProfiles: number) => {
    const reqData = {
      groupId: Number(groupId),
      skip: skipProfiles,
      isUnsure,
      failureCb: (e: Error) => {
        if (e.message === ERRORS.NETWORK_ERROR.message) {
          toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
        } else {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.PROFILE_REVIEW_FETCH_ERROR);
        }
      }
    };
    return reqData;
  }, []);

  const cellStyleFormatter = (data: any, isBordered: boolean = false) => {
    let className = '';
    if (isBordered) {
      className = 'bordered-cell';
    }
    return data.profileOneValue !== undefined &&
      data.profileTwoValue !== undefined &&
      data.profileOneValue.toString().toLowerCase() === data.profileTwoValue.toString().toLowerCase()
      ? className + ' matching-values'
      : className;
  };

  const formatProfileComaprisonData = useCallback((response: any) => {
    const profileReviewData = sortProfileData(response);
    const comparisonData = [];
    for (const keyValue of Object.keys(profileReviewData[0])) {
      if (!APPCONSTANTS.EXCLUDED_PARAMETERS_FOR_PATIENT_REVIEW.includes(keyValue)) {
        comparisonData.push({
          profileParameter: keyValue === 'villageName' ? 'Village' : getSentenceCase(keyValue),
          profileOneValue: (keyValue === 'villageName' && profileReviewData[0]['otherVillage'])
            ? profileReviewData[0]['otherVillage']?.toString().trim()
            : profileReviewData[0][keyValue]?.toString().trim(),
          profileTwoValue: (keyValue === 'villageName' && profileReviewData[1]['otherVillage'])
            ? profileReviewData[1]['otherVillage']?.toString().trim()
            : profileReviewData[1][keyValue]?.toString().trim(),
        });
      }
    }
    setProfileComparisionData(comparisonData);
  }, []);

  useEffect(() => {
    if (profileReviewData.length > 1) {
      formatProfileComaprisonData(profileReviewData);
    }
  }, [formatProfileComaprisonData, profileReviewData]);

  const getProfilesForReview = useCallback(() => {
    dispatch(
      fetchProfilesForReviewRequest({
        payload: reqPayload(groupId, isUnsure, skipProfiles),
        successCb: (status: string) => {
          if (status !== APPCONSTANTS.SUCCESS) {
            if (isUnsure === APPCONSTANTS.FALSE || !lastMarkedUnsure) {
              history.push(
                PROTECTED_ROUTES.patientDuplicateReport
                  .replace(':regionId', regionId)
                  .replace(':regionName', regionName)
                  .replace(':siteId', siteId)
                  .replace(':siteName', siteName)
              );
            } else if (lastMarkedUnsure) {
              setFinalAlert(true);
              setAlertModal({
                show: true,
                status: APPCONSTANTS.SUCCESS,
                message: getReviewSuccessMessage(APPCONSTANTS.PROFILE_UPDATE_UNSURE_STATUS_SUCCESS)
              });
            }
          }
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupId, isUnsure, reqPayload, siteId, siteName, skipProfiles]);

  useEffect(() => {
    getProfilesForReview();
  }, [dispatch, firstName, getProfilesForReview, groupId, isUnsure, lastName, reqPayload, role.label]);

  const formFields: IFormField[] = useMemo(
    () => [
      { name: 'firstName', label: 'First Name', isEdit: true },
      { name: 'middleName', label: 'Middle Name', isEdit: true },
      { name: 'lastName', label: 'Last Name', isEdit: true },
      { name: 'phoneNumber', label: 'Mobile Number', isEdit: true },
      {
        name: 'phoneNumberCategory',
        label: 'Mobile Number Category',
        isEdit: true
      },
      { name: 'age', label: 'Age', isEdit: true },
      { name: 'gender', label: 'Gender', isEdit: true },
      {
        name: 'villageName',
        label: 'Village',
        isEdit: true,
        hasId: true,
        id: 'villageId',
        other: 'otherVillage'
      },
      { name: 'landmark', label: 'Lankmark', isEdit: true },
      {
        name: 'subCountyName',
        label: 'Subcounty',
        isEdit: true,
        hasId: true,
        id: 'subCountyId'
      },
      { name: 'nationalId', label: 'National ID', isEdit: true }
    ],
    []
  );

  const getDefaultValue = (field: any, primaryProfile: any, secondaryProfile: any) => {
    let value: any = '';
    if (field.hasId) {
      if (primaryProfile[field.name]) {
        value = {
          id: primaryProfile[field.id],
          name: primaryProfile[field.name],
          ...(field.other && { other: primaryProfile[field.other] })
        };
      } else if (secondaryProfile[field.name]) {
        value = {
          id: secondaryProfile[field.id],
          name: secondaryProfile[field.name],
          ...(field.other && { other: secondaryProfile[field.other] })
        };
      }
      return value;
    } else {
      value = primaryProfile[field.name] || secondaryProfile[field.name] || '';
      return value.toString().trim();
    }
  };

  const setInitialSelectedData = useCallback(() => {
    const data = sortProfileData(profileEditFormData);
    const profileOne: any = data[0] || {};
    const profileTwo: any = data[1] || {};
    const patient: any = {
      groupId: Number(groupId),
      primaryPatientTrackId: Number(profileOne.id),
      suspectedPatientTrackId: Number(profileTwo.id)
    };
    formFields.forEach((field: any) => {
      if (profileOne.selectedFields?.length && profileOne.selectedFields.includes(field.name)) {
        patient[field.name] = getDefaultValue(field, profileOne, profileTwo);
      } else if (profileTwo.selectedFields?.length && profileTwo.selectedFields.includes(field.name)) {
        patient[field.name] = getDefaultValue(field, profileTwo, profileOne);
      }
    });
    setReviewData((prevState: any) => ({ ...prevState, patient }));
  }, [formFields, groupId, profileEditFormData]);

  useEffect(() => {
    if (profileEditFormData.length) {
      setInitialSelectedData();
    }
  }, [profileEditFormData, setInitialSelectedData]);

  const finalReviewModalRender = () => {
    const { patient = {} } = reviewData;
    return (
      <>
        {formFields.map((field: any) => (
          <div className={styles.fieldSection} key={`final-review-${field.name}`}>
            <div className={styles.fieldLabel}>{field.label}</div>:
            <div className={styles.fieldValue}>
              {field.hasId
                ? (field.other && patient[field.name]?.other) ? patient[field.name]?.other : patient[field.name]?.name
                : patient[field.name]}
            </div>
          </div>
        ))}
      </>
    );
  };

  const patientEditFormRender = () => {
    const { patient } = reviewData;
    return (
      <PatientDetailsEditForm
        formFields={formFields}
        patient={patient}
        onPatientChange={(field: any, selected: any) => {
          setReviewData((prevState: any) => ({
            ...prevState,
            patient: {
              ...prevState.patient,
              [field.name]: field.hasId ? selected : selected?.id
            }
          }));
        }}
        profileOne={profileEditFormData.length ? profileEditFormData[0] : {}}
        profileTwo={profileEditFormData.length ? profileEditFormData[1] : {}}
      />
    );
  };

  const handleMergeProfileBtnClick = () => {
    if (profileReviewData?.length > 1) {
      dispatch(
        fetchProfilesForEditFormRequest({
          payload: {
            patientTrackIds: [Number(profileReviewData[0].patientTrackId), Number(profileReviewData[1].patientTrackId)],
            failureCb: (e: Error) => {
              if (e.message === ERRORS.NETWORK_ERROR.message) {
                toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
              } else {
                toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.PROFILE_REVIEW_FETCH_ERROR);
              }
            }
          },
          successCb: () => {
            setPatienEditForm((prevState: any) => ({
              ...prevState,
              show: true
            }));
          }
        })
      );
    }
  };

  const handleReduceSuspectedProfiles = () => {
    setTotalCount(totalCount - APPCONSTANTS.NUMBER.ONE);
  };

  /*
    This is to get the remaining Profile Count after
    processing each record.
  */
  const getRemainingProfileCount = (): string => {
    return (
      totalCount -
      APPCONSTANTS.NUMBER.TWO +
      (totalCount - APPCONSTANTS.NUMBER.TWO > APPCONSTANTS.NUMBER.ONE
        ? APPCONSTANTS.PROFILES_REMAINING_MESSAGE.plural
        : APPCONSTANTS.PROFILES_REMAINING_MESSAGE.singular)
    );
  };

  const handlePatientEditSubmit = (data: any) => {
    setPatienEditForm((prevState: any) => ({ ...prevState, show: false }));
    setFinalReviewModal((prevState: any) => ({ ...prevState, show: true }));
  };
  const handlePatientEditCancel = () => setPatienEditForm((prevState: any) => ({ ...prevState, show: false }));

  const handleFinalReviewCancel = () => {
    setFinalReviewModal((prevState: any) => ({ ...prevState, show: false }));
    setPatienEditForm((prevState: any) => ({ ...prevState, show: true }));
  };

  const sortProfileData = (profileData: any) => {
    return profileData.sort((a: any, b: any) => a.profileOrder - b.profileOrder);
  };

  const handleFinalReviewSubmit = () => {
    const data = { ...reviewData.patient };
    formFields.forEach((field: IFormField) => {
      if (field.id) {
        data[field.id] = data[field.name]?.id;
        if (field?.other) {
          data[field.other] = data[field.name]?.other || null
        }
        delete data[field.name];
      }
    });
    dispatch(
      mergeProfileRequest({
        payload: {
          ...data,
          failureCb: (e: Error) => {
            if (e.message === ERRORS.NETWORK_ERROR.message) {
              toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
            } else {
              toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.PROFILE_MERGE_ERROR);
            }
          }
        },
        successCb: () => {
          setFinalReviewModal((prevState: any) => ({
            ...prevState,
            show: false
          }));
          setAlertModal((prevState: any) => ({
            ...prevState,
            show: true,
            status: APPCONSTANTS.SUCCESS,
            message: APPCONSTANTS.PROFILE_MERGE_SUCCESS
          }));
        }
      })
    );
  };

  const updateReviewStatus = (reviewType: string) => {
    if (profileReviewData.length) {
      const data = sortProfileData(profileReviewData);
      if (reviewType === APPCONSTANTS.UNSURE) {
        setSkipProfiles(skipProfiles + 1);
        setLastMarkedUnsure(true);
      } else {
        setLastMarkedUnsure(false);
      }
      const reqPayload = {
        groupId: Number(groupId),
        isUnsure,
        mainPatientTrackId: Number(data[0].patientTrackId),
        suspectedPatientTrackId: Number(data[1].patientTrackId),
        isSuspectedDuplicateUnsure: reviewType === APPCONSTANTS.UNSURE,
        isSuspectedDuplicateDifferent: reviewType === APPCONSTANTS.DIFFERENT,
        failureCb: (e: Error) => {
          if (e.message === ERRORS.NETWORK_ERROR.message) {
            toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
          } else {
            toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.PROFILE_REVIEW_STATUS_UPDATE_ERROR);
          }
        }
      };
      dispatch(
        updateProfileReviewStatusRequest({
          payload: reqPayload,
          successCb: () => {
            setAlertModal({
              show: true,
              status: APPCONSTANTS.SUCCESS,
              message: getReviewSuccessMessage(reqPayload)
            });
          }
        })
      );
    }
  };

  const getReviewSuccessMessage = (reqPayload: any) => {
    if (reqPayload.isSuspectedDuplicateDifferent) {
      return APPCONSTANTS.PROFILE_UPDATE_DIFFERENT_STATUS_SUCCESS;
    } else {
      return APPCONSTANTS.PROFILE_UPDATE_UNSURE_STATUS_SUCCESS;
    }
  };

  const alertModalRender = () => {
    return (
      <div className={styles.alertItems}>
        <img
          className={styles.alertModalIcon}
          src={alertModal.status === APPCONSTANTS.SUCCESS ? SuccessIcon : ErrorIcon}
          alt='Success'
        />
        <p className={styles.alertModalText}>{alertModal.message}</p>
        <button
          className={styles.closeButton}
          onClick={() => {
            setAlertModal({ status: '', message: '', show: false });
            handleReduceSuspectedProfiles();
            getProfilesForReview();
            if (finalAlert) {
              history.push(
                PROTECTED_ROUTES.patientDuplicateReport
                  .replace(':regionId', regionId)
                  .replace(':regionName', regionName)
                  .replace(':siteId', siteId)
                  .replace(':siteName', siteName)
              );
            }
          }}
        >
          {APPCONSTANTS.DONE}
        </button>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <div className='row'>
        <div className='col'>
          <DetailCard
            header='Review Duplicate Profiles'
            isSearch={false}
            isDisabled={false}
            secondaryText={totalCount > 2 ? getRemainingProfileCount() : null}
          >
            <CustomTable
              rowData={profileComparisionData}
              columnsDef={[
                {
                  id: 1,
                  name: 'profileParameter',
                  label: 'Profile Parameters'
                },
                {
                  id: 2,
                  name: 'profileOneValue',
                  label: 'Profile 1',
                  cellStyleFormatter
                },
                {
                  id: 3,
                  name: 'profileTwoValue',
                  label: 'Profile 2',
                  cellStyleFormatter
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={false}
              isBordered={true}
              isDownload={false}
            />
          </DetailCard>
          <div className='action-btn-grp'>
            <button
              type='submit'
              className='mt-2 btn secondary-btn alt-action-btn'
              onClick={() => updateReviewStatus(APPCONSTANTS.UNSURE)}
            >
              Unsure
            </button>
            <button
              type='submit'
              className='mt-2 ml-1 btn secondary-btn alt-action-btn'
              onClick={() => updateReviewStatus(APPCONSTANTS.DIFFERENT)}
            >
              Different Profile
            </button>
            <button
              type='submit'
              className='mt-2 ml-1 btn primary-btn alt-action-btn'
              onClick={handleMergeProfileBtnClick}
            >
              Merge Profile
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={patienEditForm.show}
        title='Final Profile Details'
        size='modal-md'
        autoHeight={true}
        isScrollNeeded={true}
        submitText='Next'
        cancelText='Cancel'
        initialValues={reviewData.patient || {}}
        handleCancel={handlePatientEditCancel}
        handleFormSubmit={handlePatientEditSubmit}
        render={patientEditFormRender}
      />
      <Modal
        show={finalReviewModal.show}
        title='Final Profile Details'
        size='modal-lg'
        autoHeight={true}
        submitText='Confirm'
        cancelText='Back'
        hasFooter
        handleCancel={handleFinalReviewCancel}
        handleFormSubmit={handleFinalReviewSubmit}
        render={finalReviewModalRender}
      />
      <Modal
        show={alertModal.show}
        isAlertModal={true}
        title=''
        size='modal-md'
        autoHeight={true}
        hasFooter={false}
        handleFormSubmit={() => ({})}
        render={alertModalRender}
      />
    </>
  );
};

export default ReviewDuplicates;
